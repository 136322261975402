import './src/css/global.css'
import * as Sentry from '@sentry/gatsby'

export const onClientEntry = () => {
  Sentry.init({
    dsn: 'https://c601cdbc912a4c8384546a87ef8fde3c@o283046.ingest.sentry.io/4504960779157504',
    environment: process.env.GATSBY_ACTIVE_ENV,
    normalizeDepth: 15,
    enabled: (() =>
      ['production', 'staging'].indexOf(process.env.GATSBY_ACTIVE_ENV) !==
      -1)(),
  })
}
