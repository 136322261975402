import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import zh from 'locales/zh/index.json'
import ja from 'locales/ja/index.json'
import en from 'locales/en/index.json'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      zh: {
        translations: zh,
      },
      ja: {
        translations: ja,
      },
      en: {
        translations: en,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    fallbackLng: 'ja',
    debug: process.env.GATSBY_ACTIVE_ENV === 'development',

    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  })

i18n.languages = ['ja', 'zh', 'en']
export default i18n
