import React, { Component } from 'react'
import i18n from 'scripts/i18n'
import { I18nextProvider, withTranslation } from 'react-i18next'

function withTrans(WrappedComponent: any) {
  WrappedComponent = withTranslation()(WrappedComponent)

  return class extends Component {
    render() {
      return (
        <I18nextProvider i18n={i18n}>
          <WrappedComponent {...this.props} language={i18n.language} />
        </I18nextProvider>
      )
    }
  }
}

export default withTrans
