// 真似した
// https://github.com/evandromacedo/react-simple-snackbar
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import CSS from 'csstype'

interface SnackbarContextType {
  openSnackbar: (text: string | string[], dataTest: string) => void
  closeSnackbar: () => void
}

// https://www.carlrippon.com/react-context-with-typescript-p2/
const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
)
export const useSnackbarContext = () => useContext(SnackbarContext)

export default function Snackbar({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false)
  const [text, setText] = useState<string | string[]>('')
  const [dataTest, setDataTest] = useState('snackbar')
  const timeoutId = useRef<NodeJS.Timeout>()

  const isSmallWindow = useMemo(() => {
    // ビルド時にwindowがundefinedになるため、エラーを回避するための処理
    if (typeof window !== 'undefined') {
      return window.innerWidth < 1000
    }
    return false
  }, [])

  const openSnackbar = (text: string | string[], dataTest = 'snackbar') => {
    setText(text)
    setDataTest(dataTest)
    setOpen(true)
  }
  const closeSnackbar = () => {
    setOpen(false)
  }
  const commonStyle: CSS.Properties = {
    transform: 'translateX(-50%)',
    left: '50%',
    color: '#fff',
    boxShadow: '0px 4px 14px rgba(0,0,0,0.4)',
    position: 'absolute',
    borderRadius: '5px',
    padding: '15px 30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1400, // @mui/material/SnackbarのzIndexが1400であるため、そちらに合わせている
  }
  const activeStyle: CSS.Properties = {
    background: '#323232',
    opacity: 1,
    top: '60px',
    transition: 'top 500ms',
    ...commonStyle,
  }
  const inactiveStyle: CSS.Properties = {
    background: 'transparent',
    opacity: 0,
    top: '20px',
    transition: 'opacity 1000ms, background 1000ms, top 1000ms',
    ...commonStyle,
  }

  const renderCloseButton = () => {
    if (isSmallWindow) {
      return <></>
    }

    return (
      <button
        data-test="closeSnackbar"
        style={{ display: 'inline', paddingLeft: 15, minWidth: 63 }}
        onClick={() => closeSnackbar()}
      >
        閉じる
      </button>
    )
  }

  useEffect(() => {
    if (open) {
      if (timeoutId.current !== undefined) {
        clearTimeout(timeoutId.current)
      }
      const id = setTimeout(() => {
        setOpen(false)
      }, 3000)
      timeoutId.current = id
      return () => {
        clearTimeout(id)
        timeoutId.current = undefined
      }
    }
  }, [open])

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      <div data-test={dataTest} style={open ? activeStyle : inactiveStyle}>
        <div style={isSmallWindow ? { fontSize: '11px' } : {}}>
          {typeof text === 'string'
            ? text
            : text.map((t) => {
                if (t) {
                  return <div>{t}</div>
                }
                return <></>
              })}
        </div>
        {renderCloseButton()}
      </div>
      {children}
    </SnackbarContext.Provider>
  )
}
